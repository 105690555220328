<template>
  <div>
    <div class="px-3 py-2" :style="`direction:${lang.dir}`">
      <b-sidebar
        aria-labelledby="sidebar-no-header-title"
        width="99vw"
        no-header
        id="crm_project_view"
        backdrop
        style="direction: ltr"
        right
        title="add order"
        shadow
      >
        <template #default="{ hide }">
          <div class="m-1 text-start" :style="`direction:` + $parent.lang.dir">
            <div
              style="
                width: 150px;
                margin-right: 10px;
                background: #000;
                color: #fff;
                border-radius: 5px;
              "
              class="btn-primary btn-sm text-center"
            >
              <span style="font-size: 0.8em"> {{ lang.projects }} </span>
            </div>
            <div
              @click="hide"
              id="vproclose"
              style="
                width: 100px;
                text-align: center;
                float: left;
                margin-left: 5px;
                margin-top: -25px;
                cursor: pointer;
              "
              class="btn-danger btn-sm"
            >
              <span>{{ $parent.lang.close }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row style="direction: rtl" class="m-2">
            <v-col cols="12">
              <div
                class="backBlack"
                style="padding: 0px; width: 100%; text-align: center"
              >
                <!-- <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:blue !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" v-b-toggle.add_gallery>{{lang.attachement}}</b-button> 
                                <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:150px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" v-b-toggle.add_addpayment>{{lang.make_payment}}</b-button>  -->
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 130px;
                    background: darkmagenta !important;
                    font-size: 1.1rem;
                    color: #fff;
                  "
                  @click="addNote()"
                  v-b-toggle.add_crmprojnote
                  >{{ lang.add_notes }}</b-button
                >
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 130px;
                    background: red !important;
                    font-size: 1.1rem;
                    color: #fff;
                  "
                  @click="addTask()"
                  v-b-toggle.add_crmtask
                  >{{ lang.add_task }}</b-button
                >
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 130px;
                    background: blue !important;
                    font-size: 1.1rem;
                    color: #fff;
                  "
                  @click="editCus()"
                  v-b-toggle.edit_CrmCase
                  >{{ lang.update }}</b-button
                >
                <img
                  :src="`../whatsappicon.png`"
                  style="
                    border: 2px solid #fff !important;
                    width: 120px;
                    margin-inline-start: 10px;
                    height: 38px;
                    border-radius: 5px;
                    cursor: pointer;
                  "
                />
              </div>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack" :style="`width:100px`">{{ lang.project_type }}</th>
                            <td :style="`${project._project_type.style};`">{{ project._project_type[`name`+lang.langname] }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.project_title }}</th>
                            <td>{{ project.project_title }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.status }}</th>
                            <td :style="`${project._status.style};`">{{ project._status[`name`+lang.langname] }}</td>
                        </tr>
                        <tr>
                            <th class="backBlack" :style="`width:100px`">{{ lang.project_start }}</th>
                            <td>{{ project.project_start }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.project_deadline }}</th>
                            <td>{{ project.project_deadline }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.project_close_date }}</th>
                            <td>{{ project.project_closed }}</td>
                        </tr>
                        <tr>
                            <td colspan="6">{{ project.notes }}</td>
                        </tr>
                    </thead>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack">{{ lang.checklist }}</th>
                            <th class="backBlack" style="width:80px;">{{ lang.close }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in project.checklist" :key="index">
                            <td>{{ item.checklists }}</td>
                            <td><b-form-checkbox v-model="item.is_closed"></b-form-checkbox></td>
                        </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="backBlack text-center" colspan="2">
                          {{ lang.project_employees }}
                        </th>
                      </tr>
                      <tr>
                        <th class="backBlack text-center" style="width:80px;">#</th>
                        <th class="backBlack text-center">{{ lang.full_name }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in project.employees" :key="index">
                        <td class="text-center">{{ item.counter }}</td>
                        <td class="text-center">{{ item.full_name }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <v-simple-table>
                        <thead>
                        <tr>
                            <th class="backBlack text-center" colspan="3">
                            {{ lang.notes }}
                            </th>
                        </tr>
                        <tr>
                            <th class="backBlack text-center" style="width:50px">{{ lang.added_date }}</th>
                            <th class="backBlack text-center" style="width:100px">{{ lang.added_by }}</th>
                            <th class="backBlack">{{ lang.notes }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in project.notes" :key="index">
                            <td class="text-center">{{ item.added_date }}</td>
                            <td class="text-center">{{ item.added_by }}</td>
                            <td class="text-center">{{ item.notes }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="backBlack text-center" colspan="8">
                          {{ lang.tasks }}
                        </th>
                      </tr>
                      <tr>
                        <th class="backBlack text-center" style="width: 100px">
                          {{ lang.date }}
                        </th>
                        <th class="backBlack text-center">{{ lang.task_description }}</th>
                        <th class="backBlack text-center" style="width: 100px">
                          {{ lang.added_by }}
                        </th>
                        <th class="backBlack text-center" style="width: 100px">
                          {{ lang.task_response }}
                        </th>
                        <th class="backBlack text-center" style="width: 100px">
                          {{ lang.last_update_date }}
                        </th>
                        <th class="backBlack text-center" style="width: 100px">
                          {{ lang.closed_by }}
                        </th>
                        <th class="backBlack text-center" style="width: 100px">
                          {{ lang.status }}
                        </th>
                        <th class="backBlack text-center" style="width: 100px">
                          {{ lang.action }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in project.tasks" :key="index">
                        <td class="text-center">{{ item.task_date }}</td>
                        <td class="text-center">{{ item.title }}</td>
                        <td class="text-center">{{ item.created_by }}</td>
                        <td class="text-center">{{ item.task_response }}</td>
                        <td class="text-center">{{ item.closed_date }}</td>
                        <td class="text-center">{{ item.closed_by == 0 ? "-" : item.closed_by }}</td>
                        <td
                          class="text-center"
                          :style="`background:${item.status_bg} !important;color:${item.status_co} !important`"
                        >
                          {{ item[`status_` + lang.langname] }}
                        </td>
                        <td
                          style="
                            margin-left: 5px;
                            width: 80px;
                            background: blue;
                            border-bottom: 2px solid #fff !important;
                            border-top: 2px solid #fff !important;
                          "
                          @click="editTask(item)"
                          v-b-toggle.update_crmtask
                        >
                          <div class="viewBTN" style="cursor: pointer">
                            {{ lang.view }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </b-sidebar>
    </div>
    <AddNewCrmNotes ref="addnotes" />
    <AddNewCrmTask ref="addtask" />
    <addInvoice ref="addInvoice" />
    <AddQuotation ref="AddQuotation" />
    <AddNewCrmLicense ref="AddNewCrmLicense" />
    <EditCrmCustomer ref="EditCrmCustomer" />
    <UpdateNewCrmTask ref="UpdateNewCrmTask" />
  </div>
</template>

<script>
import axios from "axios";
import AddNewCrmNotes from "@/components/AddNewCrmProjectNotes.vue";
import addInvoice from "@/components/addInvoice.vue";
import AddQuotation from "@/components/addQuotation.vue";
import AddNewCrmTask from "@/components/AddNewCrmTask.vue";
import UpdateNewCrmTask from "@/components/UpdateNewCrmTask.vue";
import AddNewCrmLicense from "@/components/AddNewCrmLicense.vue";
import EditCrmCustomer from "@/components/crm/EditCrmCustomer.vue";
export default {
  components: {
    AddNewCrmNotes,
    addInvoice,
    AddQuotation,
    AddNewCrmTask,
    AddNewCrmLicense,
    EditCrmCustomer,
    UpdateNewCrmTask,
  },
  data() {
    return {
      id: 0,
      customer: {},
      details: {
        // status: 1,
        // last_action: 1,
        // last_action_date: '2023-07-01',
        // agent: 'محمد علي',
        // next_action: 2,
        // next_action_date: '2023-07-08',
        // license_url: 'demo.e-carstech.com',
        // work_type: 1,
      },
      staticOption: [],
      invRows: [],
      quotRows: [],
      payRows: [],
      notesRows: [],
      tasksRows: [],
      project: {}
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },
    is_mobile: function(){
        return false;
    },
    Statics: function () {
      let t = {
        crmActions: {},
        crmStatus: {},
        crmType: {},
      };
      for (let i = 0; i < this.staticOption.length; i++) {
        if (this.staticOption[i].option_phrase == "crmActions") {
          t.crmActions[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        } else if (this.staticOption[i].option_phrase == "crmStatus") {
          t.crmStatus[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        } else if (this.staticOption[i].option_phrase == "crmType") {
          t.crmType[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        }
      }
      return t;
    },
  },
  methods: {
    addLicense(){
      this.$refs.AddNewCrmLicense.orderid = this.id;
    },
    editTask(item) {
        console.log("hello ",item);
      this.$refs.UpdateNewCrmTask.crm_id = item.id;
      this.$refs.UpdateNewCrmTask.task_response = item.agent;
      this.$refs.UpdateNewCrmTask.status = item.status;
      this.$refs.UpdateNewCrmTask.notes = '';
      this.$refs.UpdateNewCrmTask.getTaskDet();
    },
    editCus() {
      this.$refs.EditCrmCustomer.crm_id = this.id;
      this.$refs.EditCrmCustomer.work_type = this.details.work_type;
      this.$refs.EditCrmCustomer.next_action = this.details.next_action;
      this.$refs.EditCrmCustomer.status = this.details.status;
      this.$refs.EditCrmCustomer.agent = this.details.agent;
      this.$refs.EditCrmCustomer.copies = this.details.copies;
      this.$refs.EditCrmCustomer.amount = this.details.amount;
      this.$refs.EditCrmCustomer.computer = this.details.computer;
    },
    addinvoice() {
      this.$refs.addInvoice.crmid = this.id;
      this.$refs.addInvoice.cctype = this.customer.customer_type;
      this.$refs.addInvoice.changeCusType(parseFloat(this.customer.customer_type) - 1);
      this.$refs.addInvoice.customer.mobile = this.customer.mobile;
      this.$refs.addInvoice.getName(parseFloat(this.customer.customer_type) - 1);
    },
    addquotations() {
      this.$refs.AddQuotation.crmid = this.id;
      this.$refs.AddQuotation.cctype = this.customer.customer_type;
      this.$refs.AddQuotation.changeCusType(parseFloat(this.customer.customer_type) - 1);
      this.$refs.AddQuotation.customer.mobile = this.customer.mobile;
      this.$refs.AddQuotation.getName();
    },
    addNote() {
      this.$refs.addnotes.crm_id = this.id;
    },
    addTask() {
      this.$refs.addtask.crm_id = this.id;
      this.$refs.addtask.doc_type = "projects";
    },
    printPDF(id, t) {
      // if(navigator.share){
      //     navigator.share({
      //         files: [new File([this.readFile('../api/print.php?invid='+id)],'file.pdf',{type: Blob.type})],
      //         title: '',
      //         text: '',
      //     })
      // }else{
      let pdfFrame = document.body.appendChild(document.createElement("iframe"));
      pdfFrame.style.display = "none";
      pdfFrame.onload = () => void pdfFrame.contentWindow.print();
      if (t == 1) pdfFrame.src = "../api/print.php?invid=" + id;
      if (t == 2) pdfFrame.src = "../api/print.php?recpid=" + id;
      if (t == 3) pdfFrame.src = "../api/print.php?quotid=" + id;
      // }
    },
    getCRMData(id) {
      if (id == 0) {
        return false;
      }
      let post = new FormData();
      let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
      const path = this.$router.currentRoute.path;
      post.append("type", "getProjectCRMData");
      post.append("auth", cook);
      post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
      post.append("data[id]", id);
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        if (response && response.data && response.data.results) {
          this.project = response.data.results.data;
        }
      });
    },
    doComputer(id) {
      if (id == 0) {
        return this.lang.no_devices;
      } else if (id == 1) {
        return this.lang.yes_19;
      } else if (id == 2) {
        return this.lang.yes_24;
      }
    },
    async getStaticOp() {
      let post = new FormData();
      let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
      const path = this.$router.currentRoute.path;
      post.append("type", "getStaticOptions");
      post.append("auth", cook);
      post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
      post.append("data[test]", "1");
      const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post);
      if (response && response.data) {
        this.staticOption = response.data.results.data;
      }
    },
  },
  created() {
    this.getStaticOp();
  },
};
</script>
